import React, { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
//import { handleGetRequest } from "../../service/GetTemplate";
// import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
//import { useFormik } from "formik";
//import * as Yup from "yup";
import { handlePostRequest } from "../../service/PostTemplate";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ProgressSpinner } from "primereact/progressspinner";
//import { classNames } from 'primereact/utils';

const OrderRetured = ({ id, getUsersByID, onHide, orderId }) => {

    console.log("orderId", orderId)

    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();

    const validationSchema = Yup.object().shape({
      //  orderStatus: Yup.mixed().required("This field is required."),
        // message: Yup.mixed().required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            orderStatus: '',
            message: "",
        }
    })

    const handleDispatch = async () => {
        setLoading(true)
        let obj = {
            message: formik?.values?.message,
            orderId: orderId || id,
            status: selectedStatus
        }
        console.log("obj", obj)

        const res = await dispatch(handlePostRequest(obj, "api/v1/returnOrder/dispatchReturnOrder", true, true));

        if (res?.status == 200) {
            onHide()
            history.push("./returnmanagement")
        }
        setLoading(false);

    };

    const onCategChangeStatus = (e) => {
        setSelectedStatus(e.value);
    }

    const statusOption = [
        { name: 'Accept', orderStatus: 'Returned' },
        { name: 'Reject', orderStatus: 'Reject' },
    ];
    return (
        <>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <div>

                    <div className="grid p-p-3">
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Order Status
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Dropdown
                                    placeholder="--Please Select--"
                                    className="w-full md:w-10 inputClass"
                                    value={selectedStatus}
                                    name="orderStatus"
                                    id="orderStatus"
                                    options={statusOption}
                                    optionLabel="name"
                                    optionValue="orderStatus"
                                    onChange={onCategChangeStatus}

                                />
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">Message</label>
                                <InputText type="text" id="message" name="message" placeholder="Message" className="w-full md:w-10 inputClass" onChange={formik.handleChange} />
                            </div>
                        </div>

                        <div className="col-12 text-center">
                            <Button disabled={loading || !selectedStatus  ? true : false} iconPos="right" type="button" label="Submit" autoFocus className="Savebtn p-mr-3"
                                onClick={() => handleDispatch()}
                            />
                        </div>

                    </div>
                </div>
            )}
        </>
    );
}

export default OrderRetured;
