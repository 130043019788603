import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import "./login.css";
import logo from "../../../src/assets/Logo.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../../redux/slices/authenticationSlice";
import { handlePostRequest } from "../../service/PostTemplate";
import * as Yup from "yup";
import { handleGetRequest } from "../../service/GetTemplate";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

const Login = (props) => {

    const myEmail = sessionStorage.getItem("email")
    const myPassword = sessionStorage.getItem("password")
    const myIsChecked = sessionStorage.getItem("checked") || false

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address format").required("This field is required."),
        password: Yup.string().required("This field is required."),
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: myEmail ? myEmail : '',
            password: myPassword ? myPassword : '',
        },

        onSubmit: async (data) => {
            setDisableButton(true);
            setloadingIcon("pi pi-spin pi-spinner");
            setloading(true);

            const response = await dispatch(handlePostRequest(data, "api/v1/user/login", true, true));
            console.log("ress login", response)
            if (response?.status == 200) {
                getPermissionById(response?.data?.data?.role);
                localStorage.setItem("login", true);
                localStorage.setItem("username", response?.data?.data?.name);
                localStorage.setItem("email", response?.data?.data?.email);
                localStorage.setItem("roleName", response?.data?.data?.roleName);
                history.push("/");
            } else {
                setDisableButton(false);
                setloading(false);
                setloadingIcon("");
            }
        },
    });

    const getPermissionById = async (data) => {

        const response = await handleGetRequest(`api/v1/rolePermission/getByRole?roleId=${data}`, true);
        localStorage.setItem("permissions", JSON.stringify(response));
        const obj = {
            permissions: response,
            login: true,
        };

        dispatch(LOGIN_SUCCESS(obj));
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const forgetpassword1 = (e) => {
        history.push("/forgetpassword");
    };

    useEffect(() => {
        sessionStorage.setItem('email', formik.values.email)
        sessionStorage.setItem('password', formik.values.password)
        sessionStorage.setItem('checked', isChecked)

    }, [formik.values.email, formik.values.password, isChecked])

    return (
        <div className="bg_body">
            <div className="header__login">
                <h2>Welcome to</h2>
                <h3>MSafa</h3>
            </div>
            <div className="login_container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <form className="form-group" onSubmit={formik.handleSubmit}>
                            <div className="form_logo">
                                <img src={logo} alt="Zindigi" style={{ width: "100%" }} />
                            </div>
                            <div className="Form-inputfield">
                                <div>
                                    <label className="form-control" htmlFor="email">
                                        Email
                                    </label>
                                    <InputText name="email" id="email" placeholder="Enter Email" value={formik.values.email} onChange={formik.handleChange} autoFocus />
                                    {getFormErrorMessage("email")}
                                </div>
                                <div class="input-card-login flex-column p-3">
                                    <div className='flex-row'>
                                        <label> Password </label>
                                        <span className="Label__Required">*</span>
                                    </div>
                                    <Password
                                        placeholder="Enter your password"
                                        id="password"
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        toggleMask
                                        autoComplete="off"
                                        feedback={false}
                                        className="w-full custom-password-field"
                                    />
                                    <span className="ml-2">
                                        {getFormErrorMessage("password")}
                                    </span>
                                </div>
                            </div>

                            <div className="form-check flex flex-row justify-content-between mt-2">
                                <div className="text-left" >
                                    <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.checked)} />
                                    <span style={{ color: "gray" }} className="ml-2">Remember me? </span>
                                </div>
                                <span className="text-right mt-2" style={{ color: "red", cursor: "pointer" }} onClick={forgetpassword1}>
                                    Forgot password?
                                </span>
                            </div>
                            <div className="btn_class">
                                <div className="p-mt-2">
                                    <Button type="submit" className="Login_button" label="LOGIN" icon={loadingIcon || ""} iconPos="right" disabled={disableButton} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
