import React, { useState, useEffect, } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { handleGetRequest } from '../../service/GetTemplate';
import ApproveReject from './ApproveReject';
import { confirmDialog } from 'primereact/confirmdialog';
import { handleDeleteRequest } from '../../service/DeleteTemplete';
import { useDispatch } from "react-redux";
import { FilterMatchMode } from "primereact/api";
import { useHistory } from "react-router-dom";
import Delete from '../../assets/ICONS/icon_delete.png';
import { SelectButton } from 'primereact/selectbutton';
import { handlePatchRequest } from '../../service/PatchTemplete';

const Index = () => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reviewData, setReviewData] = useState([]);
    const [reviewsRowData, setReviewsRowData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [apprejdata, setAppRejData] = useState([]);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [statusValue, setStatusValue] = useState(null);
    const [updateRowData, setUpdateRowData] = useState('');
    const dispatch = useDispatch();
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const history = useHistory();
    const onHide = (name) => {
        setDisplayBasic(false);
    }

    const detailTemplate = (reviewsRowData) => {
        return (
            <Button
                tooltip="Details"
                label="Details"
                tooltipOptions={{ position: "top" }}
                className="btn btn-info ml-auto"
                onClick={() => history.push({
                    pathname: '/reviewdetail',
                    search: `?reviewId=${reviewsRowData._id}`,
                    state: { id: reviewsRowData.reviewId, data: reviewsRowData }
                }
                )}
            />
        )
    }


    const actionTemplate = (rowData) => {

        return (
            <div className="">
                {rowData.status == 'pending' ?
                    <SelectButton
                        tooltip="Update Status"
                        tooltipOptions={{ position: "top" }}
                        value={rowData.statusValue !== undefined ? rowData.statusValue : null} // Use rowData to set value
                        onChange={(e) => handleStatus(rowData, e.value)}
                        options={statusOption}
                    />
                    :
                    rowData.status == 'approved'
                        ?
                        <span style={{ fontStyle: "" }}> Review Approved Successfully</span>

                        :
                        <span style={{ fontStyle: "" }}> Action Not Permitted</span>
                }

            </div>
        );
    };

    const handleStatus = async (rowData, newValue) => {
        const obj = {
            reviewId: rowData?._id,
            isApproved: newValue,
        };

        if (newValue !== statusValue) {
            setStatusValue(newValue);
        }

        const res = await dispatch(handlePatchRequest(obj, "api/v1/review/approvedReview", true, true));
        if (res?.status === 200) {
            await getReviewsData();
        }
    };


    const actionTemplate2 = (rowData) => {

        return (
            <div className="Edit_Icon">
                <Button tooltip="Delete" tooltipOptions={{ position: "top" }} className="delete p-mr-2 p-ml-3" onClick={() => { confirm2(rowData) }} >
                    <img src={Delete} />
                </Button>
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        const result = rowData?.status;
        const color = result === 'approved' ? 'green' : result === 'pending' ? 'blue' : 'red';
        return (
            <span style={{ color: color }}>
                {rowData?.status == 'rejected' ? 'Rejected' : rowData?.status == 'approved' ? 'Approved' : 'Pending'}
            </span>
        );
    };

    const getReviewsData = async () => {
        const res = await handleGetRequest("api/v1/review/all", false);
        if (res) {
            setReviewData(res);
        }
    };

    useEffect(() => {
        getReviewsData();
    }, []);

    const RequestToDel = async () => {
        const data = {};
        data["reviewId"] = reviewsRowData;
        const res = await dispatch(handleDeleteRequest(data, `api/v1/review/`, false, false));
        if (res.status === 200) {
            getReviewsData();
        }
        setVisibleDelete(false);
    }

    useEffect(() => {
        getReviewsData();
        if (visibleDelete === true) {
            RequestToDel();
        }

    }, [visibleDelete]);

    const confirm2 = (rowData) => {
        setReviewsRowData(rowData._id);
        confirmDialog({
            message: 'Are you sure you want to delete this review?',
            header: 'Delete Confirmation',
            icon: 'pi pi-trash',
            acceptClassName: 'Savebtn',
            rejectClassName: 'Cancelbtn',
            accept,
            reject
        });
    };

    const accept = () => {
        setVisibleDelete(true);
    }

    const reject = () => {
        setVisibleDelete(false);
    }

    useEffect(() => {
        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions.modules.find(module => module.module.route.includes("/reviewsmanagement"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.name) || []);
        }
    }, []);

    const statusOption = [
        { label: 'Approve', value: true },
        { label: 'Reject', value: false },
    ];

    return (
        <div>
            <Dialog header="Detail Review" visible={displayBasic} style={{ width: '40vw' }} onHide={onHide}>
                <ApproveReject
                    onHide={onHide}
                    getReviewsData={getReviewsData}
                    reviewsRowData={reviewsRowData}
                    apprejdata={apprejdata}
                    globalFilterValue={globalFilterValue}
                />
            </Dialog>
            <div className="grid">
                <div className="col-12">
                    <div className="text-right flex float_right">
                        <div className="">
                            <span class="p-input-icon-right mr-3">
                                <input type="text" placeholder="Search" onChange={onGlobalFilterChange} class="p-inputtext p-component p-filled"
                                    onInput={(e) => setGlobalFilter(e.target.value)}
                                />
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="innr-Body">
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            rows={7}
                            paginator
                            responsiveLayout="scroll"
                            value={reviewData}
                            globalFilterFields={["customerId.contact", "channel"]}
                        >
                            <Column field="customer.name" header="Customer's Name" />
                            <Column field="customer.membershipCategory" header="Membership Category" body={(rowData) => rowData?.customer?.membershipCategory ? rowData?.customer?.membershipCategory : "N/A"} />
                            <Column field="product.name" header="Product" />
                            <Column field="customer.contact" header="Contact Number" />
                            <Column field="channel" header="Channel" />
                            {/* <Column field="createdAt" header="Created At" body={(rowData) => rowData?.createdAt ? moment(rowData?.createdAt).format('DD-MM-YYYY HH:mm') : 'N/A'} /> */}
                            <Column field="status" body={statusTemplate} header="Status" />
                            {permissions.includes("manage") ?
                                <Column body={(rowData) => actionTemplate(rowData)} header="Update Status" />
                                :
                                null
                            }
                            {permissions.includes("manage") ?
                                <Column body={actionTemplate2} header="Action" />
                                :
                                null
                            }
                            <Column body={detailTemplate} header="Detail" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Index

