import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import MemberShip from './Membership/MemberShip';
import PointsManagement from './PointsSubmenu/PointsManagement';
import CoupanPolicy from './CoupanSubmenu/CoupanPolicy';
import MembershipBenifits from './MembershipBenifits/MembershipBenifits';
import DeliveryCharges from '../DeliveryCharges.js/DeliveryCharges';

const Index = () => {

    const [permissions, setPermissions] = useState([])

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions.modules.find(module => module.module.route.includes("/customermanagement"));
        if (allPermModules) {
            const result = allPermModules?.subModules?.map(item => item?.subModule?.route)
            setPermissions(result || []);
        }
    }, []);

    return (
        <div>
            <div className="grid">
                <div className="col-12 md:col-12 lg:col-12 xs:col-12">

                    <TabView>
                        {/* {permissions?.includes("/membershipmanagement") ?
                            <TabPanel header="Membership Management" className="membershipManagement">
                                <MemberShip />
                            </TabPanel>
                            :
                            null
                        } */}
                        {permissions?.includes("/pointmanagement") ?
                            <TabPanel header="Points Management" className="pointManagement">
                                <PointsManagement />
                            </TabPanel>
                            :
                            null
                        }
                        {permissions?.includes("/couponmanagement") ?
                            <TabPanel header="Coupon Policy Management" className="coupanManagement">
                                <CoupanPolicy />
                            </TabPanel>
                            :
                            null
                        }
                        {/* {permissions?.includes("/membershipbenefit") ?
                            <TabPanel header="Membership Benefit Management" className="membershipManagement">
                                <MembershipBenifits />
                            </TabPanel>
                            :
                            null
                        }
                         */}
                        {/* {permissions?.includes("/deliverycharges") ?
                            <TabPanel header="Delivery Charges" className="membershipManagement">
                                <DeliveryCharges />
                            </TabPanel>
                            :
                            null
                        } */}
                    </TabView>
                </div>

            </div>
        </div>
    );
}

export default Index;
