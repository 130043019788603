import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Users from './Users';
import Roles from './Roles';
import AddEditAssign2 from './AddEditAssign2'
import { Icons } from 'react-toastify';


function Index() {

    const [permissions, setPermissions] = useState([])

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions.modules.find(module => module.module.route.includes("/usermanagement"));
        if (allPermModules) {
            const result = allPermModules?.subModules?.map(item => item?.subModule?.route)
            setPermissions(result || []);
        }
    }, []);

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-12 xs:col-12 lg:col-12" >
                    <TabView >
                        {permissions?.includes("/createuser") ?
                            <TabPanel header="Create User" className="createusersIcon">
                                <Users />
                            </TabPanel>
                            :
                            null
                        }
                        {permissions?.includes("/createrole") ?
                            <TabPanel header="Roles and Rights Management" className="rolesIcon">
                                <Roles />
                            </TabPanel>
                            :
                            null
                        }
                        {permissions?.includes("/createrolepermission") ?
                            <TabPanel header="Assign Permissions" className="assignIcon">
                                <AddEditAssign2 />
                            </TabPanel>
                            :
                            null
                        }
                    </TabView>
                </div>
            </div>
        </>
    );
}

export default Index;
