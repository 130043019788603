import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../service/GetTemplate";
import { FilterMatchMode } from "primereact/api";
import Edit from "../../assets/ICONS/icon_edit.png";
import { Button } from "primereact/button";
import AddEditInventory from "./AddEditInventory";
import { Dialog } from "primereact/dialog";

const Index = () => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);
    const [reviewsRowData, setReviewsRowData] = useState("");
    const [apprejdata, setAppRejData] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [productId, setProductId] = useState('');

    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const getInventoryData = async () => {
        // setloading(true);
        const res = await handleGetRequest("api/v1/inventoryStatus/all", false);

        if (res) {
            setInventoryData(res);
        }
        // setloading(false);
    };
    useEffect(() => {
        getInventoryData();
    }, []);

    const actionTemplate = (rowData) => {

        return (
            <div className="Edit_Icon">
                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit p-mr-2" onClick={() => approveReject(rowData)}>
                    <img src={Edit} />
                </Button>
            </div>
        );
    };


    const skuTemplate = (rowData) => {

        return (
            <>
                {rowData?.sku?.split("/")[1] || rowData?.sku}
            </>
        );
    };

    const rowExpansionTemplate = (data) => {

        setProductId(data?.productsId)
        return (
            <div className="grid">
                <div className="orders-subtable card col-12">
                    <h5>Variants of Product: <span><b> {data?.productsName} </b></span></h5>

                    <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="innr-Body">
                            <DataTable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                value={data?.variants}
                                responsiveLayout="scroll"
                            >
                                <Column field="colorName" header="Color Name" body={(rowData) => rowData.colorName ? rowData.colorName : "N/A"} />
                                <Column field="colorHex" header="Color Hex" body={(rowData) => rowData.colorHex ? rowData.colorHex : "N/A"} />
                                <Column field="actualPrice" header="Actual Price" sortable />
                                <Column field="discountedPrice" header="Discounted Price" body={(rowData) => rowData.discountedPrice ? rowData.discountedPrice : "N/A"} />
                                <Column field="quantity" header="Quantity" sortable />
                                <Column field="size" header="Size" body={(rowData) => rowData.size ? rowData.size : "N/A"} />
                                <Column body={skuTemplate} header="SKU" sortable />
                                {permissions.includes("manage") ?
                                    <Column body={actionTemplate} header="Action" />
                                    :
                                    null
                                }
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0};</>;
    };

    const approveReject = (rowData) => {
        setDisplayBasic(true);
        setAppRejData(true);
        setReviewsRowData(rowData);
    };

    const onHide = (name) => {
        setDisplayBasic(false);
        setAppRejData(false);
        getInventoryData();
    };

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions.modules.find(module => module.module.route.includes("/inventorystatus"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.name) || []);
        }
    }, []);

    return (
        <div>
            <Dialog header="Update" visible={displayBasic} style={{ width: "40vw" }} onHide={onHide}>
                <AddEditInventory
                    onHide={onHide}
                    reviewsRowData={reviewsRowData}
                    apprejdata={apprejdata}
                    displayBasic={displayBasic}
                    getInventoryData={getInventoryData}
                    productId={productId}
                />
            </Dialog>

            <div className="grid">
                <div className="col-12 md:col-12 xl:col-12 lg:col-12">
                    <div className="text-right flex float_right">
                        <div className="">
                            <span class="p-input-icon-right mr-3">
                                <input type="text" placeholder="Search" onChange={onGlobalFilterChange} class="p-inputtext p-component p-filled" onInput={(e) => setGlobalFilter(e.target.value)} />
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                        {/* <div className="">
                            < div className="flex flex-column">                               
                                 <Dropdown placeholder="--Please Select--" className="w-full md:w-10 inputClass Drop_down" value={selectedCateg} options={categ} onChange={onCategChange} optionLabel="name" placeholder="Select Category" />
                            </div>
                        </div>
                        <div className="">
                            <div className="flex flex-column">

                                 <Dropdown placeholder="--Please Select--" className="w-full md:w-10 inputClass Drop_down" value={selectedCateg} options={categ} onChange={onCategChange} optionLabel="name" placeholder="Select Sub-Category" />
                            </div>
                        </div> */}
                        {/* <div className="">
                            <button className="p-button p-button-primary p-component" >

                                <span className="p-button-label p-c">Export to excel</span>
                                <span className="p-ink"></span>
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="col-12 md:col-12 xl:col-12 lg:col-12">
                    <div className="innr-Body">
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            rows={7}
                            paginator
                            responsiveLayout="scroll"
                            value={inventoryData}
                            globalFilterFields={["productsName", "categoryName", "subcategoryName", "remainingQuantity"]}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => {
                                setExpandedRows(e.data);
                            }}
                            rowExpansionTemplate={rowExpansionTemplate}
                        >
                            <Column expander={allowExpansion} style={{ width: "3em" }} />
                            {/* <Column field="productsId" header="Product-ID" /> */}
                            <Column
                                body={(data, props) => {
                                    return <div>{props.rowIndex + 1}</div>;
                                }}
                                header="Serial"
                            />
                            <Column field="productsName" header="Product Name" />
                            <Column field="categoryName" header="Category" />
                            <Column field="subcategoryName" header="Sub-Category" />
                            {/* <Column field="remainingQuantity" header=" Inventory" /> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
