//let baseURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_LIVE;

//For Local
//  let baseURL = "http://192.168.12.97:3015/";

//For QA
// let baseURL = "https://uat-msafaapi.appinsnap.com/";

//For UAT
// let baseURL = "http://20.212.227.60:3065/";

//For Prod
let baseURL = "https://api.msafa.ca/";

export { baseURL };
