import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../service/GetTemplate";
// import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../service/PostTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { useFormik, validateYupSchema } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";

const OrderDispatch = ({ status, trackingId, id, myOrderSplitId2 }) => {

    const [selectedStatus, setSelectedStatus] = useState();
    const [warehouseData, setWarehouseData] = useState([]);
    const [postExData, setPostExData] = useState([]);
    
    const [loading, setloading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        // courierType: Yup.string().required("This field is required."),
        // originCityCode: Yup.string().required("This field is required."),
        orderType: Yup.string().required("Order type is required."),
       // description: Yup.string().required("Description is required."),
        packing: Yup.string().required("Packing is required."),
        weight: Yup.number().required("Weight is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            // courierType: "",
            // orderStatusType: "",
            // originCityCode: "",
            orderType: "",
            description: "",
            packing: "",
            weight: "",
        },

        onSubmit: async (data) => {
            setloading(true);
            data["orderStatus"] = selectedStatus;
            data["orderId"] = id;

            delete data["undefined"];

            if (formik.values.orderStatusType === "Canceled") {
                data["parcelId"] = trackingId;
            }

            let obj = {
                description: formik.values.description,
                orderType: formik.values.orderType,
                weight: formik.values.weight,
                packing: formik.values.packing,
                orderId: myOrderSplitId2 || id,
            };

            const res = await dispatch(handlePostRequest(obj, "api/v1/order/orderDispatch", true, true));

            if (res?.status === 200) {
                // setOrderId(res);
            }

            setloading(false);
            history.push("./ordermanagement");
        },
    });

    async function handlePostEX() {
        try {
            const response = await fetch("http://20.212.227.60:3007/api/v1/courier/postEx/warehouse");
            const data = await response.json();

            setPostExData(data?.dist);

            return data;
        } catch (error) { }
    }

    const handleSWFTY = async () => {
        const res = await handleGetRequest("api/v1/courier/swyft/operationalCities", false);
        if (res) {
            setWarehouseData(res);
        }
    };

    useEffect(() => {
        if (formik.values.courierType === "PostEx") {
            handlePostEX();
        } else if (formik.values.courierType === "Swfyt") {
            handleSWFTY();
        } else {
            formik.setFieldValue(formik.values.courierType, "");
        }
    }, [formik.values.courierType]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const DilveryOption = [
        { name: "PostEx", id: 0 },
        { name: "Swfyt", id: 1 },
    ];
    const OrderOption = [
        { name: "Delivered", orderStatusType: "Delivered" },
        { name: "Canceled", orderStatusType: "Canceled" },
    ];
    const OrdertypeOption = [
        { name: "Normal", orderType: "Normal" },
        { name: "Reverse", orderType: "Reverse" },
        { name: "Replacement", orderType: "Replacement" },
    ];
    const PackingtypeOption = [{ name: "Flyer", packing: "Flyer" }];

    return (
        <div>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid p-p-3">
                        {/* <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">Delivery Partner</label>
                             <Dropdown placeholder="--Please Select--"

                                id="courierType"
                                name="courierType"
                                value={'POSTEX'}
                                onChange={formik.handleChange}
                                className="w-full md:w-10 inputClass"
                                options={DilveryOption}
                                optionLabel="name"
                                optionValue="name"

                            />
                            {getFormErrorMessage("courierType")}
                        </div>
                    </div> */}

                        {/* <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">Order Status</label>

                             <Dropdown placeholder="--Please Select--"

                                id="orderStatusType"
                                name="orderStatusType"
                                value={formik.values.orderStatusType}
                                onChange={formik.handleChange}
                                className="w-full md:w-10 inputClass"
                                options={OrderOption}
                                optionLabel="name"
                                optionValue="orderStatusType"

                            />

                        </div>
                    </div> */}

                        {/* <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">Ware House</label>

                             <Dropdown placeholder="--Please Select--"
                                id="originCityCode"
                                name="originCityCode"
                                value={formik?.values?.originCityCode}
                                onChange={formik.handleChange}
                                className="w-full md:w-10 inputClass"
                                options={formik.values.courierType.includes("PostEx") ? postExData : warehouseData}
                                optionLabel={formik.values.courierType === "PostEx" ? "address" : "name"}
                                optionValue={formik.values.courierType === "PostEx" ? "addressCode" : "code"}

                            />
                            {getFormErrorMessage("courierType")}
                        </div>
                    </div> */}
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Order Type <span style={{ color: "red" }}>*</span>
                                </label>

                                <Dropdown placeholder="--Please Select--" id="ordercourierTypeType" name="orderType" value={formik?.values?.orderType} onChange={formik.handleChange} className="w-full md:w-10 inputClass" options={OrdertypeOption} optionLabel="name" optionValue="orderType" />
                                {getFormErrorMessage("orderType")}
                            </div>
                        </div>

                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Packing <span style={{ color: "red" }}>*</span>
                                </label>

                                <Dropdown placeholder="--Please Select--" id="packing" name="packing" value={formik?.values?.packing} onChange={formik.handleChange} className="w-full md:w-10 inputClass" options={PackingtypeOption} optionLabel="name" optionValue="packing" />

                                {getFormErrorMessage("packing")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label htmlFor="fromDate">
                                    Weight (kg) <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    id="weight"
                                    name="weight"
                                    value={formik?.values?.weight}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const trimmedValue = inputValue.slice(0, 4);
                                        formik.handleChange({ target: { id: "weight", value: trimmedValue } });
                                    }}
                                    className={classNames({ "p-invalid": isFormFieldValid("weight") }, "w-full md:w-10 inputClass")}
                                    type="number"
                                    maxLength="4"
                                />
                                {getFormErrorMessage("weight")}

                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label htmlFor="fromDate">
                                    Description
                                </label>
                                <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("description") }, "w-full md:w-10 inputClass")} optionlabel="description" type="description" />
                                {getFormErrorMessage("description")}
                            </div>
                        </div>

                        {/* <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">Ordered ID </label>
                            <InputText type="text" placeholder="Enter" className="w-full md:w-10 inputClass" value={orderId} />
                        </div>
                    </div> */}
                        <div className="col-12 text-center">
                            {/* <Button disabled={loading} iconPos="right" label={"Submit"} autoFocus className="Savebtn p-mr-3"
                            onClick={() => handleDispatch()} /> */}

                            <Button
                                // autoFocus
                                className="Savebtn"
                                label={"Submit"}
                                disabled={loading}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            )}
        </div>

    );
};

export default OrderDispatch;
