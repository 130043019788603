import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import CategoryManagement from './CategoryManagement/CategoryManagement';
//import SubCategoryManagement from './subCategoryManagement/subCategoryManagement';
import ProductManagement from './ProductManagement/ProductManagement';

const Index = () => {

    const [permissions, setPermissions] = useState([])

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions.modules.find(module => module.module.route.includes("/salemanagement"));
        if (allPermModules) {
            const result = allPermModules?.subModules?.map(item => item?.subModule?.route)
            setPermissions(result || []);
        }
    }, []);

    return (

        <div>
            <div className="grid">
                <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                    <TabView >
                        {permissions?.includes("/categorymanagement") ?
                            <TabPanel header="Category Management" className="categoryIcon">
                                <CategoryManagement />
                            </TabPanel>
                            :
                            null
                        }
                        {permissions?.includes("/productmanagement") ?
                            <TabPanel header="Product Management" className="productIcon">
                                <ProductManagement />
                            </TabPanel>
                            :
                            null
                        }
                        {/* {permissions?.includes("/subcategory") ?
                            <TabPanel header="Sub-Category Management" className="subCategoryIcon">
                                <SubCategoryManagement />
                            </TabPanel>
                            :
                            null
                        } */}

                    </TabView>
                </div>
            </div>
        </div>
    );
}

export default Index;
