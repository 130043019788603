import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../service/GetTemplate";
import { handlePostRequest } from "../../../service/PostTemplate";
import { handlePatchRequest } from "../../../service/PatchTemplete";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

const Addedit = ({ onHide, getPointdata, addeditable, pointRowData, pointsdata }) => {

    const maxOrderPriceFrom = pointsdata[pointsdata.length - 1]?.pointOrderPriceFrom;
    console.log("maxOrderPriceFrom", maxOrderPriceFrom)
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const initialPoints = localStorage.getItem('initialPoint')
    console.log("initialPoints", initialPoints)

    const getMembersByID = async () => {
        const data = {};
        data["pointManageId"] = pointRowData;
        const res = await handleGetRequest(`api/v1/pointManage/getOne?pointId=${pointRowData}`, true);

        setloading(false);
        if (res) {
            const keyData = res;
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('initialPoint', res?.initialPoint)
        }
    };
    useEffect(() => {
        if (pointRowData !== undefined && pointRowData !== null && addeditable === true) {
            getMembersByID();
        }
    }, []);

    const validationSchema = Yup.object().shape({
        initialPoint: addeditable ? Yup.mixed().required("This field is required.") : null,
        pointOrderPriceTo: Yup.number()
            .required("This field is required."),
        pointOrderPriceFrom: Yup.number()
            .when('pointOrderPriceTo', (pointOrderPriceTo, schema) => {
                return schema
                    .required("This field is required.")
                    .min(pointOrderPriceTo, "Point order price to value must be greater than or equal to From Point order price from value");
            }),
        pointPerOrder: Yup.mixed().required("This field is required."),
        ReedemPoints: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            initialPoint: "",
            pointOrderPriceFrom: "",
            pointOrderPriceTo: "",
            pointPerOrder: "",
            ReedemPoints: "",
        },

        onSubmit: async (data) => {

            setloading(true);
            if (addeditable === true) {
                data["pointManageId"] = pointRowData;
                const res = await dispatch(handlePatchRequest(data, "api/v1/pointManage", true, true));
                if (res.status === 200) {
                    await getPointdata();
                }
                onHide();
            } else {
                data["initialPoint"] = initialPoints;
                if (data['pointOrderPriceTo'] < maxOrderPriceFrom) {
                    console.log("inside")
                    toast.warn(`Point Order Price From cannot be greater than ${maxOrderPriceFrom}`);
                    formik.setFieldValue('pointOrderPriceTo', 0)
                    setloading(false);
                    return
                }
                const res = await dispatch(handlePostRequest(data, "api/v1/pointManage", true, true));
                if (res?.status === 200 || res?.status === 201) {
                    await getPointdata();
                }
                onHide();
            }
            setloading(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid p-p-3">
                {addeditable ?
                    <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">Sign-Up Points</label>
                            <InputText min={3} name="initialPoint" id="initialPoint" type="number" className={classNames({ "p-invalid": isFormFieldValid("initialPoint") }, "w-full md:w-10 inputClass")} value={formik.values.initialPoint} onChange={formik.handleChange} placeholder="" />
                        </div>
                        {getFormErrorMessage("initialPoint")}
                    </div>
                    :
                    null}

                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="flex flex-column">
                        <label className="mb-2">Order Price From</label>
                        <InputText
                            disabled={addeditable ? true : false}
                            min={0}
                            keyfilter={/^[0-9!@#$%^&*]+$/}
                            maxLength={10}
                            name="pointOrderPriceTo"
                            id="pointOrderPriceTo"
                            className={classNames(
                                { "p-invalid": isFormFieldValid("pointOrderPriceTo") },
                                "w-full md:w-10 inputClass"
                            )}
                            value={formik.values.pointOrderPriceTo}
                            onChange={formik.handleChange}
                            placeholder=""
                        />
                    </div>
                    {getFormErrorMessage("pointOrderPriceTo")}
                </div>

                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="flex flex-column">
                        <label className="mb-2">Order Price To</label>
                        <InputText
                            disabled={formik.values.pointOrderPriceTo === '' || addeditable ? true : false}
                            maxLength={10}
                            keyfilter={/^[0-9!@#$%^&*]+$/}
                            name="pointOrderPriceFrom"
                            id="pointOrderPriceFrom"
                            type="text"
                            className={classNames(
                                { "p-invalid": isFormFieldValid("pointOrderPriceFrom") },
                                "w-full md:w-10 inputClass"
                            )}
                            value={formik.values.pointOrderPriceFrom}
                            onChange={formik.handleChange}
                            // onChange={(e) => {
                            //     const priceFrom = parseFloat(e.target.value);
                            //     const priceTo = parseFloat(formik.values.pointOrderPriceTo);
                            //     if (!isNaN(priceFrom) && !isNaN(priceTo) && priceFrom <= priceTo) {
                            //         toast.warn('Order Price From can not be greater than Order Price To')
                            //     } else {
                            //         formik.handleChange(e);
                            //     }
                            // }}
                            placeholder=""
                        />
                    </div>
                    {getFormErrorMessage("pointOrderPriceFrom")}
                </div>

                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="flex flex-column">
                        <label className="mb-2">Points Per Order</label>
                        <InputText min={3}
                            keyfilter={/^[0-9!@#$%^&*]+$/}
                            maxLength={6} name="pointPerOrder" id="pointPerOrder" className={classNames({ "p-invalid": isFormFieldValid("pointPerOrder") }, "w-full md:w-10 inputClass")} value={formik.values.pointPerOrder} onChange={formik.handleChange} placeholder="" />
                    </div>
                    {getFormErrorMessage("pointPerOrder")}
                </div>

                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="flex flex-column">
                        <label className="mb-2">Redeem Points</label>
                        <InputText min={3}
                            keyfilter={/^[0-9!@#$%^&*]+$/}
                            maxLength={6} name="ReedemPoints" id="ReedemPoints" className={classNames({ "p-invalid": isFormFieldValid("ReedemPoints") }, "w-full md:w-10 inputClass")} value={formik.values.ReedemPoints} onChange={formik.handleChange} placeholder="" />
                    </div>
                    {getFormErrorMessage("ReedemPoints")}
                </div>

                <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                    <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                    <Button
                        // autoFocus
                        className="Savebtn"
                        label={addeditable ? "Update" : "Save"}
                        disabled={loading}
                        type="submit"
                    />
                </div>
            </div>
        </form>
    );
};

export default Addedit;
