import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from "yup";
import { handleGetRequest } from "../../service/GetTemplate";
import { useDispatch } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { useHistory, useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { baseURL } from "../../utilities/Config";
import OrderRetured from "./OrderRetured";

const AddEditReturn = (props) => {

    let { search, state } = useLocation();

    const orderId = localStorage.getItem('orderId')
    const myOrderSplitId = search?.split('=')[1];

    const query = new URLSearchParams(search);
    const orderRowData = query.get("orderid");
    const editable = orderRowData ? true : false;

    const [loading, setLoading] = useState(false);
    const [fileUploadData, setfileUploadData] = useState("");
    const [loadingIcon, setloadingIcon] = useState("pi pi-save");
    const [displayDialog, setDisplayDialog] = useState(false);
    const [productData, setProductData] = useState([]);
    const [returnProductNew, setReturnProudctNew] = useState([]);
    const [returnProduct, setReturnProduct] = useState();
    const [productDataa, setProductDataa] = useState([]);
    const [exchange, setExchange] = useState();
    const [shipment, setShipment] = useState();
    const [IsOrderReturn, setIsOrderReturn] = useState();
    const [data, setData] = useState([]);
    const [orderImage, setOrderImage] = useState();
    const [productImage, setProductImage] = useState();
    const [newOrderData, setNewOrderData] = useState([]);

    const history = useHistory();
    const dispatch = useDispatch();

    const onHide = (name) => {
        setDisplayDialog(false);
    };

    useEffect(() => {

    }, [data])

    const handleDialog = () => {

        setDisplayDialog(true);

    };
    const handleCancel = (e) => {
        e.preventDefault();
        history.push("./returnmanagement")
        onHide();
    };

    const getUsersByID = async () => {
        const data = {};
        data["roleId"] = orderRowData;
        setLoading(true);
        const res = await handleGetRequest(`api/v1/returnOrder/details?orderId=${orderRowData}`, true);
        setIsOrderReturn(res?.isOrderReturn)

        setProductDataa(res?.returnProduct)
        setReturnProduct(res?.orderId?.orderId)

        setExchange(res?.exchangeReason)
        setShipment(res?.shipmentType)
        setProductData(res?.orderId?.product);
        setReturnProudctNew(res?.returnProduct[0]);
        setData(res?.images);
        setOrderImage(res?.images);
        setProductImage(res?.images);
        setNewOrderData(res);

        if (res) {
            const keyData = res;
            setLoading(false);
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            const customerName = keyData?.orderId?.customer?.firstName + " " + keyData?.orderId?.customer?.lastName;
            formik.setFieldValue("customerName", customerName)
            const address = keyData?.orderId?.shippingAddress?.addressLine;
            formik.setFieldValue("address", address)
            const contact = keyData?.orderId?.shippingAddress?.contact;
            formik.setFieldValue("contact", contact)

            const province = keyData?.orderId?.shippingAddress?.province;
            formik.setFieldValue("province", province)

            const zipCode = keyData?.orderId?.shippingAddress?.zipCode;
            formik.setFieldValue("zipCode", zipCode)

            const region = keyData?.orderId?.shippingAddress?.Shippingregion;
            formik.setFieldValue("region", region)
        }
    };

    useEffect(() => {
        if (orderRowData !== undefined && orderRowData !== null && editable === true) {
            getUsersByID();
        }
    }, [orderRowData]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        description: Yup.string().required("This field is required.").nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            customerName: "",
            address: "",
            contact: "",
            quantity: "",
            price: "",
            province: "",
            zipCode: "",
            regipn: "",
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const returnProductTempalte = () => {
        return <React.Fragment>{returnProduct}</React.Fragment>;
    };

    const exchangeTemplate = () => {

        return <React.Fragment>
            {exchange}
        </React.Fragment>;
    };

    const shipmentTypeTemplate = () => {

        return <React.Fragment>
            {shipment}
        </React.Fragment>;
    };

    const imageTemplate = (rowData) => {
        console.log("order image", rowData?.images)
        return (
            <React.Fragment>
                <img className='tbl__coupanImage' src={rowData?.images?.length ? `${baseURL}${rowData?.images}` : ''} alt="" />
                {/* <Image src={`${baseURL}/${orderRowData}`} zoomSrc={`http://20.212.227.60:3007/${data}`} alt="Image" width="80" height="60" preview /> */}
            </React.Fragment>
        );
    };

    const imageTemplate2 = (orderRowData) => {

        console.log("productImage", productImage)
        return (
            <React.Fragment>
                <img className='tbl__coupanImage' src={productImage?.length ? `${baseURL}${productImage[0]}` : ''} alt="" />
                {/* <Image src={`${baseURL}/${orderRowData}`} zoomSrc={`http://20.212.227.60:3007/${data}`} alt="Image" width="80" height="60" preview /> */}
            </React.Fragment>
        );
    };

    return (
        <>
            <Dialog header="Order Returned" visible={displayDialog} style={{ width: "40vw" }} closable={true} onHide={onHide}>
                <OrderRetured
                    orderId={myOrderSplitId}
                    id={state?.id}
                    getUsersByID={getUsersByID}
                    onHide={onHide}
                />
            </Dialog>

            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="card headr_bg">
                        <div className="card-header">
                            <label>Details</label>
                        </div>
                        <div className="card-body">
                            <div className="grid">
                                <div className="col-12 md:col-12 xl:col-12 lg:col-12 pt-3 pb-3">
                                    <label>
                                        <b> {IsOrderReturn ? 'Return Order' : 'Return Product'} </b>
                                    </label>
                                </div>

                                {IsOrderReturn ?
                                    <div className="col-12 innr_padding mt-1 mb-1">
                                        <div className="grid">
                                            <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                                                <div className="innr-Body">
                                                    <DataTable rows={5} responsiveLayout="scroll" value={[newOrderData]} >
                                                        <Column field="orderId.orderId" header='Order Id' />
                                                        <Column body={imageTemplate} header="Image" />
                                                        <Column field="shipmentType" header="Shipment Type" />
                                                        <Column field="exchangeReason" header="Exchange Reason" />
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-12 innr_padding mt-1 mb-1">
                                        <div className="grid">
                                            <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                                                <div className="innr-Body">
                                                    <DataTable rows={5} responsiveLayout="scroll" value={productDataa} >
                                                        <Column body={returnProductTempalte} header='Order Id' />
                                                        <Column body={imageTemplate2} header="Image" />
                                                        <Column body={shipmentTypeTemplate} header="Shipment Type" />
                                                        <Column body={exchangeTemplate} header="Exchange Reason" />
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 md:col-12 xl:col-12 lg:col-12 pt-3 pb-3">
                                    <label>
                                        <b> Customer Details</b>
                                    </label>
                                </div>
                                <div className="col-12 md:col-4 xl:col-4 lg:col-4">
                                    <div className="flex flex-column">
                                        <label className="mb-2">Customer's Name</label>
                                        <InputText disabled={true} id="customerName" name="customerName" value={formik?.values?.customerName} type="text" placeholder="Enter" className="w-full md:w-10 inputClass" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-4 xl:col-4 lg:col-4">
                                    <div className="flex flex-column">
                                        <label className="mb-2">Delivery Address</label>
                                        <InputText disabled={true} id="address" name="address" value={formik?.values?.address} type="text" placeholder="Enter" className="w-full md:w-10 inputClass" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-4 xl:col-4 lg:col-4">
                                    <div className="flex flex-column">
                                        <label className="mb-2">Contact Number</label>
                                        <InputText disabled={true} id="contact" name="contact" value={formik?.values?.contact} type="text" placeholder="Enter" className="w-full md:w-10 inputClass" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-4 xl:col-4 lg:col-4">
                                    <div className="flex flex-column">
                                        <label className="mb-2">Province</label>
                                        <InputText disabled={true} id="province" name="province" value={formik?.values?.province} type="text" placeholder="Enter" className="w-full md:w-10 inputClass" />
                                    </div>
                                </div>

                                <div className="col-12 md:col-4 xl:col-4 lg:col-4">
                                    <div className="flex flex-column">
                                        <label className="mb-2">Zip Code</label>
                                        <InputText disabled={true} id="zipCode" name="zipCode" value={formik?.values?.zipCode} type="text" placeholder="Enter" className="w-full md:w-10 inputClass" />
                                    </div>
                                </div>

                                <div className="col-12 md:col-4 xl:col-4 lg:col-4">
                                    <div className="flex flex-column">
                                        <label className="mb-2">Region</label>
                                        <InputText disabled={true} id="region" name="region" value={formik?.values?.region} type="text" placeholder="Enter" className="w-full md:w-10 inputClass" />
                                    </div>
                                </div>

                                <div className="col-12 md:col-12 xl:col-12 lg:col-12 pt-3 pb-3">
                                    <label>
                                        <b> {IsOrderReturn ? 'Order Details' : 'Product Details'}</b>
                                    </label>
                                </div>
                                <div className="col-12 innr_padding mt-1 mb-1">
                                    <div className="grid">
                                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                                            {IsOrderReturn ?
                                                <div className="innr-Body">
                                                    <DataTable rows={5} responsiveLayout="scroll" value={productData} >
                                                        <Column field="productId.name" header="Product Name" />
                                                        <Column field="quantity" header="Quantity" />
                                                        <Column field="price" header="Price" />
                                                    </DataTable>
                                                </div>
                                                :
                                                <div className="innr-Body">
                                                    <DataTable rows={5} responsiveLayout="scroll" value={[returnProductNew]} >
                                                        <Column field="productId.name" header="Product Name" />
                                                        <Column field="quantity" header="Quantity" />
                                                        <Column
                                                            field="sku"
                                                            header="Sku"
                                                            body={(rowData) => rowData?.sku?.includes('/') ? rowData?.sku.substring(rowData.sku.lastIndexOf('/') + 1) : rowData?.sku}
                                                        />
                                                        <Column field="size" header="Size" body={(rowData) => rowData?.size ? rowData?.size : 'N/A'} />
                                                        <Column field="colour" header="Colour" body={(rowData) => rowData?.colour ? rowData?.colour : 'N/A'} />
                                                        <Column field="price" header="Price" body={(rowData) => rowData?.price ? rowData?.price : 'N/A'} />
                                                    </DataTable>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-center pt-4">
                                    <Button label="Cancel" onClick={(e) => handleCancel(e)} className="Cancelbtn p-mr-3" />
                                    <Button autoFocus className="Savebtn" label="Process" onClick={handleDialog} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default AddEditReturn;
