import React, { useState, useEffect, } from 'react';
import { handleGetRequest } from '../../service/GetTemplate';
import { useHistory, useLocation } from "react-router-dom";
import { Rating } from 'primereact/rating';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { baseURL } from '../../utilities/Config';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const ReviewsDetail = () => {

    let { search, state } = useLocation();
    const query = new URLSearchParams(search);
    const reviewRowData = query.get("reviewId");
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reviewData, setReviewData] = useState([]);
    const history = useHistory()

    const getReviewsDetail = async () => {
        const res = await handleGetRequest(`api/v1/review/customerReviewDetails?reviewId=${reviewRowData}`, true);
        const singleObj = [res]
        setReviewData(singleObj);
    };

    useEffect(() => {
        getReviewsDetail();
    }, []);

    const ratingTemplate = (rowData) => {
        return <div><Rating value={rowData?.rating} readOnly stars={5} cancel={false} /></div>;
    }
    const imageTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Image src={`${baseURL}${rowData.images}`} zoomSrc={`http://20.212.227.60:3007/${rowData.images}`} alt="Image" width="80" height="60" preview />
            </React.Fragment>
        );
    };

    const handleBack = (e) => {
        e.preventDefault();
        history.goBack();
    };

    return (
        <div>

            <div className="grid">
                <div className="col-12">
                    <div className='flex justify-content-between'>
                        <Button label="Back" onClick={(e) => handleBack(e)} className="Cancelbtn" />
                        <div className="text-right flex float_right">
                            <div className="">
                                <span class="p-input-icon-right mr-3">
                                    <input type="text" placeholder="Search" class="p-inputtext p-component p-filled" onInput={(e) => setGlobalFilter(e.target.value)} />
                                    <i class="pi pi-search"></i>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12">
                    <div className="innr-Body">
                        <DataTable value={reviewData}>
                            <Column field="customerName" header="Customer Name"></Column>
                            <Column field="productName" header="Produckjjjt Name"></Column>
                            <Column body={ratingTemplate} header="Rating"></Column>
                            <Column field="comment" header="Comment"></Column>
                            <Column body={imageTemplate} header="Images"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewsDetail;