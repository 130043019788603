import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../service/GetTemplate";
import { handlePatchRequest } from "../../service/PatchTemplete";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

const AddEditInventory = ({ productRowData, reviewsRowData, apprejdata, getReviewsData, onHide, getInventoryData, productId }) => {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [sku, setSku] = useState([]);
    const [concatSku, setConcatSku] = useState("");
    const [selectedSku, setSelectedSku] = useState(null);
    const [disable, setDisable] = useState(false);
    const [isOnPromotion, setIsOnPromotion] = useState(false);

    const getMembersByID = async () => {
        const res = await handleGetRequest(`api/v1/products/details?productId=${productId}`, false);
        if (res) {
            const result = res?.variant.filter(item => item?._id == reviewsRowData?._id)
            setIsOnPromotion(result[0]?.isOnPromotion);
        }
    };

    useEffect(() => {

        if (reviewsRowData !== undefined && reviewsRowData !== null && apprejdata === true) {
            setConcatSku(reviewsRowData?.sku)
            console.log("reviewsRowData", reviewsRowData)
            formik.setFieldValue("sku", reviewsRowData?.sku?.includes('/') ? reviewsRowData?.sku?.split('/')[1] : reviewsRowData?.sku)
            formik.setFieldValue("actualPrice", reviewsRowData?.actualPrice)
            formik.setFieldValue("discountedPrice", reviewsRowData?.discountedPrice)
            formik.setFieldValue("quantity", reviewsRowData?.quantity)
            getMembersByID()
        }
    }, [reviewsRowData]);

    const validationSchema = Yup.object().shape({
        sku: Yup.mixed().required("SKU is missing"),
        actualPrice: Yup.number().moreThan(0, "Actual price must be greater than 0").required("Actual price is missing"),
        //discountedPrice: isOnPromotion ? Yup.number().optional() : Yup.number().when("actualPrice", (actualPrice, schema) => schema.lessThan(actualPrice, `Discounted price must be less than actual price (${actualPrice})`)),
        quantity: Yup.number().moreThan(-1, "Quantity can not be less then 0").required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            sku: "",
            actualPrice: "",
            discountedPrice: "",
            quantity: "",
        },

        onSubmit: async (data) => {
            setDisable(true);
            try {
                if (apprejdata === true) {
                    let obj = {
                        productId: productId,
                        sku: concatSku,
                        actualPrice: formik.values.actualPrice,
                        discountedPrice: formik.values.discountedPrice,
                        quantity: formik.values.quantity,
                    }

                    console.log("obj", obj)

                    if(formik.values.quantity == 0)
                    {
                        toast.warn('Quantity can not be less than 1')
                        formik.setFieldValue('quantity', 0)
                        return
                    }

                    setLoading(true);
                    const res = await dispatch(handlePatchRequest(obj, "api/v1/products/quantityUpdate", true, true));
                    if (res?.status === 200) {

                        onHide();
                        getInventoryData();
                        await getReviewsData();
                    } else {
                        // console.log(res?.data?.msg[0]);
                        // toast.error(res?.data?.msg[0]);
                    }
                    setDisable(false);
                    setLoading(false);
                    onHide();
                }
            } catch (error) {
            } finally {
                // onHide();
            }
        },
    });

    const getAndOnHide = () => {
        getReviewsData();

        onHide();
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleDiscountedPriceChange = (e) => {
        const { name, value } = e.target;    
        if (name === "discountedPrice") {
            const actualPrice = formik.values.actualPrice || 0;
            const newDiscountedPrice = value || 0;
    
            if (parseFloat(newDiscountedPrice) > parseFloat(actualPrice)) {
               
                return;
            }
        }
    
        formik.setFieldValue(name, value);
    };
    
    useEffect(() => {
        if (formik.values.discountedPrice !== "" && formik.values.actualPrice !== "" && formik.values.discountedPrice > formik.values.actualPrice) {
            // toast.warn("Discounted Price can't be more than actual price");
            // setDisable(true);
            return;
        } else {
            setDisable(false);
        }
    }, [formik.values.discountedPrice, formik.values.actualPrice]);

    useEffect(() => {
        if (selectedSku && formik.values.quantity !== selectedSku.quantity) {
            formik.setFieldValue("quantity", selectedSku.quantity);
        }
    }, [selectedSku, formik.values.quantity]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid p-p-3">
                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="flex flex-column">
                        <label className="mb-2">Product SKU</label>
                        <InputText
                            disabled
                            placeholder="SKU"
                            id="sku"
                            name="sku"
                            value={formik.values.sku}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("sku") }, "w-full md:w-10 inputClass")}
                        />
                        {getFormErrorMessage("sku")}
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div className="flex flex-column">
                        <label className="mb-2">Actual Price</label>
                        <InputText maxLength={7} keyfilter={/^[0-9!@#$%^&*]+$/}  placeholder="Acutal Price" id="actualPrice" type="text" name="actualPrice" value={formik?.values?.actualPrice} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("actualPrice") }, "w-full md:w-10 inputClass")} />
                        {getFormErrorMessage("actualPrice")}
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div className="flex flex-column">
                        <label className="mb-2">Product Discounted Price</label>
                        <InputText
                            placeholder="Discounted Price"
                            id="discountedPrice"
                            type="text"
                            keyfilter={/^[0-9!@#$%^&*]+$/} 
                            maxLength={6}
                            name="discountedPrice"
                            value={formik?.values?.discountedPrice}
                            disabled={isOnPromotion}
                            onChange={handleDiscountedPriceChange}
                            className={classNames({ "p-invalid": isFormFieldValid("discountedPrice") }, "w-full md:w-10 inputClass")}
                        />
                        {getFormErrorMessage("discountedPrice")}
                        {isOnPromotion && <sub style={{ color: "red", fontStyle: "italic" }}>Product is already on Promotion</sub>}
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="flex flex-column">
                        <label className="mb-2">Quantity</label>
                        <InputText
                            placeholder="Quantity"
                            type="text"
                            min="0"
                            keyfilter={/^[0-9!@#$%^&*]+$/} 
                            maxLength={6}
                            id="quantity"
                            name="quantity"
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "w-full md:w-10 inputClass")}
                        />
                        {getFormErrorMessage("quantity")}
                    </div>
                </div>

                <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                    <Button className="Savebtn" label="Update" loading={loading} type="submit" onHide={onHide} />
                </div>
            </div>
        </form>
    );
};

export default AddEditInventory;
