import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { handleGetRequest } from "../../service/GetTemplate";
import { handleDeleteRequest } from "../../service/DeleteTemplete";
import { useDispatch } from "react-redux";
import AddEditUsers from "./AddEditUsers";
import { FilterMatchMode } from "primereact/api";
import Edit from "../../../src/assets/ICONS/icon_edit.png";
import Delete from "../../../src/assets/ICONS/icon_delete.png";

const Tab1 = () => {
    const dispatch = useDispatch();
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [editable, setEditable] = useState(false);
    const [usersRowData, setUsersRowData] = useState("");
    const [userData, setUserData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    
    var selectedDeleteId;

    const onHide = () => {
        setEditable(false);
        setVisibleEdit(false);
    };
    
    const RolesTemplate = (rowData) => {
        const permissions = rowData?.role;
        return <React.Fragment>{permissions?.name}</React.Fragment>;
    };

    const getUserData = async () => {
        const res = await handleGetRequest("api/v1/user/all", false);
        if (res) {
            setUserData(res);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const RequestResetPassword = async () => {
        const data = {};
        data["userId"] = selectedDeleteId;

        const res = await dispatch(handleDeleteRequest(data, `api/v1/user/`, true, true));
        if (res?.status === 200) {
            getUserData();
        } else {
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit" onClick={() => editUsers(rowData)}>
                    <img src={Edit} />
                </Button>
                <Button tooltip="Delete" tooltipOptions={{ position: "top" }} className="delete" onClick={() => confirm2(rowData)}>
                    <img src={Delete} />
                </Button>
            </div>
        );
    };

    const editUsers = (rowData) => {
        setVisibleEdit(true);
        setEditable(true);
        setUsersRowData(rowData._id);
    };
    const confirm2 = (rowData) => {
        // setUsersRowData(rowData._id);
        selectedDeleteId = rowData._id;
        confirmDialog({
            message: "Are you sure you want to delete this user?",
            header: "Delete Confirmation",
            icon: "pi pi-trash",
            acceptClassName: "Savebtn",
            rejectClassName: "Cancelbtn",
            accept,
            reject,
        });
    };
    const accept = () => {
        RequestResetPassword();
        setVisibleDelete(true);
    };

    const reject = () => {
        setVisibleDelete(false);
    };

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions?.modules?.find(module => module?.module?.route?.includes("/usermanagement"));
        if (allPermModules) {
          const result = allPermModules?.subModules?.filter(item => item?.subModule?.route == "/createuser")
          const result2 = result[0]?.permissions && result[0]?.permissions.map(item => item.name)
          setPermissions(result2 || []);
        }
      }, []);

    return (
        <>
            <Dialog header={editable ? "Edit" : "Add New User"} visible={visibleEdit} style={{ width: "40vw" }} onHide={() => onHide("displayBasic")}>
                <AddEditUsers getUserData={getUserData} editable={editable} onHide={onHide} UsersRowData={usersRowData} />
            </Dialog>

            <div className="grid">
                <div className="col-12  md:col-12 lg:col-12 xl:col-12">
                    <div className="text-right">
                        <span className="p-input-icon-right mr-3">
                            <input type="text" placeholder="Search" onChange={onGlobalFilterChange} value={globalFilterValue} className="p-inputtext p-component p-filled" />
                            <i className="pi pi-search"></i>
                        </span>
                        {permissions?.includes("create") ? (
                            <button className="p-button p-button-primary p-component" onClick={() => setVisibleEdit(true)}>
                                <span className="p-button-icon p-c p-button-icon-left pi pi-plus"></span>
                                <span className="p-button-label p-c">Add New</span>
                                <span className="p-ink"></span>
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="innr-Body">
                        <DataTable
                            globalFilter={globalFilter}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            rows={7}
                            paginator
                            //rowsPerPageOptions={[10, 25, 50, 100]}
                            responsiveLayout="scroll"
                            value={userData}
                            filters={filters}
                            globalFilterFields={["role.name", "name", "email", "contact"]}
                        >
                            <Column field="name" header="Name" />
                            <Column body={RolesTemplate} field="role.name" header="User Role" />
                            <Column field="email" header="Email" />
                            <Column field="contact" header="Contact Number" />
                            {permissions?.includes("manage") ? <Column body={actionTemplate} header="Action" /> : null}
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tab1;
