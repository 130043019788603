import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { handlePatchRequest } from "../../service/PatchTemplete";
import { handlePostRequest } from "../../service/PostTemplate";
import { useDispatch } from "react-redux";

const AddEditDeliveryCharges = ({ addEditDelivery, getDeliveryData, deliveyRowData, onHide }) => {

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        Region: Yup.mixed().required("Text type is required"),
        Charges: Yup.mixed().required("Text head is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            Region: '',
            Charges: '',
        },

        enableReinitialize: true,
        onSubmit: async (data) => {
            const obj = {
                Region: deliveyRowData?.Region?._id,
                Charges: formik.values.Charges,
            }
            setLoading(true);
            if (addEditDelivery === true) {
                const res = await dispatch(handlePatchRequest(obj, `api/v1/deliveryCharges/${deliveyRowData?._id}`, true, true));

                if (res.status === 200) {
                    await getDeliveryData();
                    onHide();
                }
            } else {
                const res = await dispatch(handlePostRequest(obj, "api/v1/deliveryCharges/", true, true));
                if (res?.status === 200 || res?.status === 201) {
                    await getDeliveryData();
                    onHide();
                }
            }
            setLoading(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (deliveyRowData && addEditDelivery) {
            formik.setFieldValue('Region', deliveyRowData?.Region?.taxType)
            formik.setFieldValue('Charges', deliveyRowData?.deliveryCharges)
        }
    }, [addEditDelivery, deliveyRowData])

    return (
    
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid p-p-3">
                    <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">
                                Region <span style={{ color: "red" }}>*</span>
                            </label>
                            <InputText disabled name="Region" maxLength={16} id="Region" type="text" className={classNames({ "p-invalid": isFormFieldValid("Region") }, "w-full md:w-10 inputClass")} value={formik.values.Region} onChange={formik.handleChange} />
                            {getFormErrorMessage("Region")}
                        </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">
                               Delivery Charges <span style={{ color: "red" }}>*</span>
                            </label>
                            <InputText  keyfilter={/^[0-9.!@#$%^&*]+$/} maxLength={7} name="Charges" type='' id="Charges" className={classNames({ "p-invalid": isFormFieldValid("Charges") }, "w-full md:w-10 inputClass")} value={formik.values.Charges} onChange={formik.handleChange} />
                            {getFormErrorMessage("Charges")}
                        </div>
                    </div>
                    <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                        <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                        <Button autoFocus className="Savebtn" label={addEditDelivery ? "Update" : "Save"} type="submit" loading={loading} />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddEditDeliveryCharges;
